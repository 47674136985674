import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {
  MixedWidget14,MixedWidget6,StatsWidget10,
} from "../../../_metronic/_partials/widgets";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export function BaseDashboardAssets({ className }) {

  const [key, setKey] = useState("Month");
  const [value, setValue] = useState(0);
  

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <>
      {/* <Card>

        <div className="App">
          <div className="row">

            <div className="col-4"> TOTAL BDM	<Chart
              options={state.options}
              series={state.series}
              type="bar"
              width="350" />

            </div>

            <div className="col-4"> TOTAL PPM<Chart
              options={state.options}

              series={state.series}
              type="area"
              width="350" />

            </div>
            <div className="col-4"> AMOUNT SPENT<Chart
              options={state.options}
              series={state.series}
              type="radar"
              width="350" />

            </div>



          </div>
        </div>
      </Card> */}

      <Card>
        <div className="row">
          
		  
            <div className="col-md-4">
			 <div
              className={`card card-custom bg-radial-gradient-danger ${className}`}
            >
              {/* begin::Header */}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title font-weight-bolder text-white">
                  Completion % 
                </h3>
              </div>
			  </div>
              {/* end::Header */}
			
              <MixedWidget14
                className="gutter-b"
                symbolShape="circle"
                baseColor="danger"
              
			  
			  />
			  
			  
            </div>
            
           
           
      
		  
		  
		  <div className="col-md-4">
            <div
              className={`card card-custom bg-radial-gradient-danger ${className}`}
            >
              {/* begin::Header */}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title font-weight-bolder text-white">
                  Assets 
                </h3>
              </div>
              {/* end::Header */}

              {/* begin::Body */}
              <div className="card-body d-flex flex-column p-0">
                {/* begin::Stats */}
                <div className="card-spacer bg-white card-rounded flex-grow-1">

                  
                  {/* begin::Row */}
                  <div className="row m-0">
                    <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
                      <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">

                      </span>
                      <div className="text-warning font-weight-bold font-size-h7">
                        Total - 58
                      </div>
                      {/* <div className="font-size-h4 font-weight-bolder"></div> */}
                    </div>
                    <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">
                      <div className="text-primary font-weight-bold font-size-h7 mt-2">
                        Open - 27
                      </div>
                      {/* <div className="font-size-h4 font-weight-bolder">27</div> */}
                    </div>
                  </div>
                  {/* end::Row */}
                  {/* begin::Row */}
                  <div className="row m-0">
                    <div className="col bg-light-danger px-6 py-8 rounded-xl mr-7">
                      <div className="text-danger font-weight-bold font-size-h7 mt-2">
                        Overdue - 27
                      </div>
                      {/* <div className="font-size-h4 font-weight-bolder">27</div> */}
                    </div>
                    <div className="col bg-light-success px-6 py-8 rounded-xl">
                      <div className="text-success font-weight-bold font-size-h7 mt-2">
                        Closed - 23
                      </div>
                      {/* <div className="font-size-h4 font-weight-bolder">23</div> */}
                    </div>
                  </div>
				  
				  <div className="d-flex flex-column w-100 mr-2  mb-15">
                    
                  </div>
				  
				  <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="text-muted mr-2 font-size-sm font-weight-bold">
                        Total Completion - 65%
						

                      </span>
                      <span className="text-muted font-size-sm font-weight-bold">
                        Progress
                      </span>
                    </div>
                    <div className="progress progress-xs w-100">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: "85%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  {/* end::Row */}
                </div>
                {/* end::Stats */}
              </div>
              {/* end::Body */}
            </div>
          </div>
		  
          
           <div className="col-md-4">
            <div
              className={`card card-custom bg-radial-gradient-danger ${className}`}
            >
              {/* begin::Header */}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title font-weight-bolder text-white">
                  W.O Aging 
                </h3>
              </div>
              {/* end::Header */}

              {/* begin::Body */}
              <div className="card-body d-flex flex-column p-0">
                {/* begin::Stats without white card in this page*/}
               

                <MixedWidget6/>
				  
				 
		
                
                {/* end::Stats */}
              </div>
              {/* end::Body */}
            </div>
          </div>
		
		
		
		</div>
      </Card>

      <br />
	  
	  <Card>
	  
	  <div className="row">
        <div className="col-xl-4">
          <div className="row">
            <div className="col-xl-12">
              <StatsWidget10
                className="gutter-b"
                symbolShape="circle"
                baseColor="danger"
              />
			  
            </div>
            
            
          </div>
        </div>
        
		
		
      </div>
	  </Card>
	  
	  
	  
	  
	  
	  
	  
      <Card>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="BDM Summary" />
            <Tab label="PPM Summary" />
            <Tab label="Critical Assets" />
          </Tabs>
        </AppBar>
        {value === 0 &&
          <TabContainer>
            <div className={`card card-custom ${className}`}>
              {/* Head */}
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label font-weight-bolder text-dark">
                    BDM Summary
                  </span>
                  {/* <span className="text-muted mt-3 font-weight-bold font-size-sm">
                    More than 400+ new members
                  </span> */}
                </h3>
                <div className="card-toolbar">
                  <TabContainer defaultActiveKey={key}>
                    <Nav
                      as="ul"
                      onSelect={_key => setKey(_key)}
                      className="nav nav-pills nav-pills-sm nav-dark-75"
                    >
                      <Nav.Item className="nav-item" as="li">
                        <Nav.Link
                          eventKey="Month"
                          className={`nav-link py-2 px-4 ${key === "Month" ? "active" : ""
                            }`}
                        >
                          Month
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-item" as="li">
                        <Nav.Link
                          eventKey="Week"
                          className={`nav-link py-2 px-4 ${key === "Week" ? "active" : ""
                            }`}
                        >
                          Week
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-item" as="li">
                        <Nav.Link
                          eventKey="Day"
                          className={`nav-link py-2 px-4 ${key === "Day" ? "active" : ""
                            }`}
                        >
                          Day
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </TabContainer>
                </div>
              </div>
              {/* Body */}
              <div className="card-body pt-3 pb-0">
                <div className="table-responsive">
                  <table className="table table-borderless table-vertical-center">
                    <thead>
                      <tr>
                        <th className="p-0" style={{ minWidth: "100px" }}>
                          Work Order Number
                        </th>
                        <th className="p-0" style={{ minWidth: "100px" }}>
                          Work Order Date
                        </th>
                        <th className="p-0" style={{ minWidth: "85px" }}>
                          Priority
                        </th>
                        <th className="p-0" style={{ minWidth: "80px" }}>
                          Status
                        </th>
                        <th className="p-0" style={{ minWidth: "150px" }}>
                          Nature of Compliant
                        </th>
                        <th className="p-0" style={{ minWidth: "150px" }}>
                          Location / Building
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="pl-0">
                          <span className="text-muted font-weight-500">
                            WO-001
                          </span>
                        </td>
                        <td className="pl-0">
                          <span className="text-muted font-weight-500">
                            20/11/2023
                          </span>
                        </td>
                        <td className="pl-0">
                          <span className="text-muted font-weight-500">
                            High
                          </span>
                        </td>
                        <td className="pl-0">
                          <span className="label label-lg label-light-primary label-inline">
                            Approved
                          </span>
                        </td>
                        <td className="pl-0">
                          <span className="text-muted font-weight-500">
                            AC Not Working
                          </span>
                        </td>
                        <td className="pl-0">
                          <span className="text-muted font-weight-500">
                            Dubai
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="pl-0">
                          <span className="text-muted font-weight-500">
                            WO-002
                          </span>
                        </td>
                        <td className="pl-0">
                          <span className="text-muted font-weight-500">
                            21/11/2023
                          </span>
                        </td>
                        <td className="pl-0">
                          <span className="text-muted font-weight-500">
                            Medium
                          </span>
                        </td>
                        <td className="pl-0">
                          <span className="label label-lg label-light-danger label-inline">
                            Rejected
                          </span>
                        </td>
                        <td className="pl-0">
                          <span className="text-muted font-weight-500">
                            Plumbing
                          </span>
                        </td>
                        <td className="pl-0">
                          <span className="text-muted font-weight-500">
                            Dubai
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </TabContainer>
        }



      </Card>
    </>
  );
}
