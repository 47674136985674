import React, { useEffect, useMemo } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../layout";

export function PriorityWise({ priorityWise, className }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("PriorityWise");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps, priorityWise);
    const chart = new ApexCharts(element, {
      ...options,
      series: priorityWise.closed || [],
    });

    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, priorityWise]);

  return (
    <div className="col-md-4">
      <div className={`card card-custom bg-radial-gradient-white ${className}`}>
        <div className="card-header border-0 pt-5">
          <h3 className="card-title font-success-bolder text-black">
            Priority Wise
          </h3>
        </div>
        <div className="card-body d-flex flex-column p-0">
          <div id="PriorityWise" style={{ height: "250px" }}>
          </div>
          <div className="card-spacer bg-white card-rounded flex-grow-1"></div>
        </div>
      </div>
    </div>
  );
}

function getChartOption(layoutProps, priorityWise) {
  const defaultOptions = {
    labels: priorityWise.prioritys || [],
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: false,
    },
    colors: ["#d8bfd8", "#e9967a", "#87cefa"],
    grid: {
      borderColor: layoutProps.colorsGrayGray200,
      strokeDashArray: 4,
      padding: {
        left: 20,
        right: 20,
      },
    },
    chart: {
      type: "donut",
      height: "250px",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
  };

  return defaultOptions;
}

