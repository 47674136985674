/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDizrCTz-CYVDUzxJo5nhLasSZL58FsWAo",
    authDomain: "xyntrom.firebaseapp.com",
    projectId: "xyntrom",
    storageBucket: "xyntrom.appspot.com",
    messagingSenderId: "567241871739",
    appId: "1:567241871739:web:67ae9bf4914db4a7006dde",
    measurementId: "G-1P0YTM6JN2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;