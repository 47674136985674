import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const ContractPage = lazy(() =>
  import("./modules/Custom/contract/ContractPage")
);

const SubcontractPage = lazy(() =>
  import("./modules/Custom/subcontract/SubcontractPage")
);

const OnetimeprojectPage = lazy(() =>
  import("./modules/Custom/onetimeproject/OnetimeprojectPage")
);

const EmployeePage = lazy(() =>
  import("./modules/Custom/employee/EmployeePage")
);

const EmployeeDeploymentPage = lazy(() =>
  import("./modules/Custom/employeeDeployment/EmployeePage")
);

const ChecklistPage = lazy(() =>
  import("./modules/Custom/checklist/ChecklistPage")
);

const CustomerPage = lazy(() =>
  import("./modules/Custom/customer/CustomerPage")
);

const CreatetaskPage = lazy(() =>
  import("./modules/Custom/createtask/CreatetaskPage")
);

const UserpermissionPage = lazy(() =>
  import("./modules/Custom/userpermission/UserpermissionPage")
);

const PreventivemaintenancePage = lazy(() =>
  import("./modules/Custom/preventivemaintenance/PreventivemaintenancePage")
);

const PpmSchedulePage = lazy(() =>
  import("./modules/Custom/ppmschedule/PpmSchedulePage")
);

const OrganizationPage = lazy(() =>
  import("./modules/Custom/organization/OrganizationPage")
);

const SalarystructurePage = lazy(() =>
  import("./modules/Custom/salarystructure/SalarystructurePage")
);
const PayrollPage = lazy(() =>
  import("./modules/Custom/payroll/PayrollPage")
);
const AttendancePage = lazy(() =>
  import("./modules/Custom/attendance/AttendancePage")
);

const LeavePage = lazy(() =>
  import("./modules/Custom/leave/LeavePage")
);

const CountryPage = lazy(() =>
  import("./modules/Custom/country/CountryPage")
);

const CitysPage = lazy(() =>
  import("./modules/Custom/citys/CitysPage")
);

const LocationsPage = lazy(() =>
  import("./modules/Custom/locations/LocationsPage")
);

const BuildingsPage = lazy(() =>
  import("./modules/Custom/buildings/BuildingsPage")
);

const FloorsPage = lazy(() =>
  import("./modules/Custom/floors/FloorsPage")
);

const SpotsPage = lazy(() =>
  import("./modules/Custom/spots/SpotsPage")
);

const ErpPage = lazy(() =>
  import("./modules/Custom/erp/ErpPage")
);

const AssetPage = lazy(() =>
  import("./modules/Custom/asset/AssetPage")
);

const AssetMigrationPage = lazy(() =>
  import("./modules/Custom/assetmigration/AssetMigrationPage")
);

const AssignmentPage = lazy(() =>
  import("./modules/Custom/assignment/AssignmentPage")
);

const LpoPage = lazy(() =>
  import("./modules/Custom/lpo/LpoPage")
);

const GrnPage = lazy(() =>
  import("./modules/Custom/grn/GrnPage")
);

const CnPage = lazy(() =>
  import("./modules/Custom/cn/CnPage")
);

const InvoicePage = lazy(() =>
  import("./modules/Custom/invoice/InvoicePage")
);

const ReportPage = lazy(() =>
  import("./modules/Custom/report/ReportPage")
);

const MaterialPage = lazy(() =>
  import("./modules/Custom/material/MaterialPage")
);

const UsersPage = lazy(() =>
  import("./modules/Custom/users/UsersPage")
);

const MaterialRequestPage = lazy(() =>
  import("./modules/Custom/material_request/MaterialRequestPage")
);

const PurchaseRequestPage = lazy(() =>
  import("./modules/Custom/purchase_request/PurchaseRequestPage")
);

const MaterialIssuePage = lazy(() =>
  import("./modules/Custom/material_issue/MaterialIssuePage")
);

const SettingsPage = lazy(() =>
  import("./modules/Custom/settings/SettingsPage")
);

const UserTypePage = lazy(() =>
  import("./modules/Custom/userType/UserTypePage")
);

const UserLogPage = lazy(() =>
  import("./modules/Custom/userLog/UserLogPage")
);

const BrandingPage = lazy(() =>
  import("./modules/Custom/branding/BrandingPage")
);

const ArraivedatSitePage = lazy(() =>
  import("./modules/Custom/arrivedatSite/ArraivedatSitePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/contract" component={ContractPage} />
        <Route path="/employee" component={EmployeePage} />
        <Route path="/checklist" component={ChecklistPage} />
        <Route path="/employeeDeployment" component={EmployeeDeploymentPage} />
        <Route path="/customer" component={CustomerPage} />
        <Route path="/createtask" component={CreatetaskPage} />
        <Route path="/userpermission" component={UserpermissionPage} />
        <Route path="/organization" component={OrganizationPage} />
        <Route path="/salarystructure" component={SalarystructurePage} />
        <Route path="/preventivemaintenance" component={PreventivemaintenancePage} />
        <Route path="/ppmschedule" component={PpmSchedulePage} />
        <Route path="/attendance" component={AttendancePage} />
        <Route path="/leave" component={LeavePage} />
        <Route path="/payroll" component={PayrollPage} />
        <Route path="/country" component={CountryPage} />
        <Route path="/citys" component={CitysPage} />
        <Route path="/locations" component={LocationsPage} />
        <Route path="/buildings" component={BuildingsPage} />
        <Route path="/floors" component={FloorsPage} />
        <Route path="/spots" component={SpotsPage} />
        <Route path="/erp" component={ErpPage} />
        <Route path="/lpo" component={LpoPage} />
        <Route path="/grn" component={GrnPage} />
        <Route path="/cn" component={CnPage} />
        <Route path="/asset" component={AssetPage} />
        <Route path="/assetmigration" component={AssetMigrationPage} />
        <Route path="/assignment" component={AssignmentPage} />
        <Route path="/invoice" component={InvoicePage} />
        <Route path="/report" component={ReportPage} />
        <Route path="/material" component={MaterialPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/material_request" component={MaterialRequestPage} />
        <Route path="/purchase_request" component={PurchaseRequestPage} />
        <Route path="/material_issue" component={MaterialIssuePage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/userType" component={UserTypePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/subcontract" component={SubcontractPage} />
        <Route path="/onetimeproject" component={OnetimeprojectPage} />
        <Route path="/userLog" component={UserLogPage} />
        <Route path="/branding" component={BrandingPage} />
        <Route path="/arrivedatSite" component={ArraivedatSitePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}