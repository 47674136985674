import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../layout";
import { KTUtil } from "../../_assets/js/components/util";

export function CompletionPercentage({ percent, onTime, delayed, className }) {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
      colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_14_chart");
    if (!element) {
      return;
    }

    const height = parseInt(KTUtil.css(element, 'height'));
    const options = getChartOptions(layoutProps, height, percent);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, percent]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column">
        <div className="flex-grow-1">
          <div id="kt_mixed_widget_14_chart" style={{ height: "250px" }}></div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <span className="text-success mr-2 font-size-sm font-weight-bold">
            On Time {`${onTime}`}%
          </span>
          <span className="text-danger font-size-sm font-weight-bold">
            Delayed {`${delayed}`}%
          </span>
        </div>
        <div className="progress progress-xs w-100">
          <div
            className="progress-bar bg-success"
            role="progressbar"
            style={{ width: `${onTime}%` }}
            aria-valuenow={onTime}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            className="progress-bar bg-danger"
            role="progressbar"
            style={{ width: `${delayed}%` }}
            aria-valuenow={delayed}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
  );
}

function getChartOptions(layoutProps, height, percent) {
  const options = {
    series: [percent],
    chart: {
      height: height,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "65%"
        },
        dataLabels: {
          showOn: "always",
          name: {
            show: false,
            fontWeight: "700",
          },
          value: {
            color: layoutProps.colorsGrayGray700,
            fontSize: "30px",
            fontWeight: "700",
            offsetY: 12,
            show: true
          },
        },
        track: {
          background: layoutProps.colorsThemeLightSuccess,
          strokeWidth: '100%'
        }
      }
    },
    colors: [layoutProps.colorsThemeBaseSuccess],
    stroke: {
      lineCap: "round",
    },
    labels: ["Progress"]
  };
  return options;
}