/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout"

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update time every second

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);
  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      )
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle((aside && aside.title && aside.title.length > 0) ? aside.title : header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader]);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
        </div>

        {/* Toolbar */}
        <div className="d-flex align-items-center">
          <span className="text-dark font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">{currentTime.toLocaleString("en-US", { weekday: 'long' })}</span>
          <span className="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">
            <span style={{ color: 'blue' }}>{currentTime.toLocaleString("en-US", { day: '2-digit' })}</span>&nbsp;
            <span style={{ color: 'blue' }}>{currentTime.toLocaleString("en-US", { month: "long" })}</span>&nbsp;
            <span style={{ color: 'blue' }}>{currentTime.getFullYear()}</span>&nbsp;
            <span style={{ color: 'red' }}>
              {currentTime.toLocaleString("en-US", {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true 
              })}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
