/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef,no-unused-vars */
import React, {useState} from "react";
import {Tab} from "react-bootstrap";

export function QuickPanel() {
  const [selectedTab, setSelectedTab] = useState("AuditLogs");

  return (
      <div id="kt_quick_panel" className="offcanvas offcanvas-left pt-5 pb-10">
        <Tab.Container
            defaultActiveKey={selectedTab}
        >
          {/*begin::Header*/}
        
          {/*end::Header*/}

          {/*begin::Content*/}
          <div className="offcanvas-content px-10">
            <div className="tab-content">
              <div
                  id="kt_quick_panel_logs"
                  role="tabpanel"
                  className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps ${selectedTab === "AuditLogs" ? "active show" : ""}`}
              >
                <div className="mb-15">
                  <h5 className="font-weight-bold mb-5">Filter </h5>
                  

                 
				 
				 
				 
                 

                </div>
                </div>

               
              <div
                  id="kt_quick_panel_notifications"
                  role="tabpanel"
                  className={`tab-pane fade pt-2 pr-5 mr-n5 scroll ps ${selectedTab === "Notifications" ? "active show" : ""}`}
              >
                <div className="navi navi-icon-circle navi-spacer-x-0">
                  <a href="#" className="navi-item">
                    <div className="navi-link rounded">
                      <div className="symbol symbol-50 mr-3">
                        <div className="symbol-label">
                          <i className="flaticon-bell text-success icon-lg"></i>
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold font-size-lg">
                          5 new user generated report
                        </div>
                        <div className="text-muted">Reports based on sales</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="navi-item">
                    <div className="navi-link rounded">
                      <div className="symbol symbol-50 mr-3">
                        <div className="symbol-label">
                          <i className="flaticon2-box text-danger icon-lg"></i>
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold  font-size-lg">
                          2 new items submited
                        </div>
                        <div className="text-muted">by Grog John</div>
                      </div>
                    </div>
                  </a>

                  <a href="#" className="navi-item">
                    <div className="navi-link rounded">
                      <div className="symbol symbol-50 mr-3">
                        <div className="symbol-label">
                          <i className="flaticon-psd text-primary icon-lg"></i>
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold  font-size-lg">
                          79 PSD files generated
                        </div>
                        <div className="text-muted">Reports based on sales</div>
                      </div>
                    </div>
                  </a>

                  <a href="#" className="navi-item">
                    <div className="navi-link rounded">
                      <div className="symbol symbol-50 mr-3">
                        <div className="symbol-label">
                          <i className="flaticon2-supermarket text-warning icon-lg"></i>
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold  font-size-lg">
                          $2900 worth producucts sold
                        </div>
                        <div className="text-muted">Total 234 items</div>
                      </div>
                    </div>
                  </a>

                  <a href="#" className="navi-item">
                    <div className="navi-link rounded">
                      <div className="symbol symbol-50 mr-3">
                        <div className="symbol-label">
                          <i className="flaticon-paper-plane-1 text-success icon-lg"></i>
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold  font-size-lg">
                          4.5h-avarage response time
                        </div>
                        <div className="text-muted">Fostest is Barry</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="navi-item">
                    <div className="navi-link rounded">
                      <div className="symbol symbol-50 mr-3">
                        <div className="symbol-label">
                          <i className="flaticon-safe-shield-protection text-danger icon-lg"></i>
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold  font-size-lg">
                          3 Defence alerts
                        </div>
                        <div className="text-muted">
                          40% less alerts thar last week
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="navi-item">
                    <div className="navi-link rounded">
                      <div className="symbol symbol-50 mr-3">
                        <div className="symbol-label">
                          <i className="flaticon-notepad text-primary icon-lg"></i>
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold  font-size-lg">
                          Avarage 4 blog posts per author
                        </div>
                        <div className="text-muted">Most posted 12 time</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="navi-item">
                    <div className="navi-link rounded">
                      <div className="symbol symbol-50 mr-3">
                        <div className="symbol-label">
                          <i className="flaticon-users-1 text-warning icon-lg"></i>
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold  font-size-lg">
                          16 authors joined last week
                        </div>
                        <div className="text-muted">
                          9 photodrapehrs, 7 designer
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="navi-item">
                    <div className="navi-link rounded">
                      <div className="symbol symbol-50 mr-3">
                        <div className="symbol-label">
                          <i className="flaticon2-box text-info icon-lg"></i>
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold  font-size-lg">
                          2 new items have been submited
                        </div>
                        <div className="text-muted">by Grog John</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="navi-item">
                    <div className="navi-link rounded">
                      <div className="symbol symbol-50 mr-3">
                        <div className="symbol-label">
                          <i className="flaticon2-download text-success icon-lg"></i>
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold  font-size-lg">
                          2.8 GB-total downloads size
                        </div>
                        <div className="text-muted">
                          Mostly PSD end AL concepts
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="navi-item">
                    <div className="navi-link rounded">
                      <div className="symbol symbol-50 mr-3">
                        <div className="symbol-label">
                          <i className="flaticon2-supermarket text-danger icon-lg"></i>
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold  font-size-lg">
                          $2900 worth producucts sold
                        </div>
                        <div className="text-muted">Total 234 items</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="navi-item">
                    <div className="navi-link rounded">
                      <div className="symbol symbol-50 mr-3">
                        <div className="symbol-label">
                          <i className="flaticon-bell text-primary icon-lg"></i>
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold  font-size-lg">
                          7 new user generated report
                        </div>
                        <div className="text-muted">Reports based on sales</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="navi-item">
                    <div className="navi-link rounded">
                      <div className="symbol symbol-50 mr-3">
                        <div className="symbol-label">
                          <i className="flaticon-paper-plane-1 text-success icon-lg"></i>
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold  font-size-lg">
                          4.5h-avarage response time
                        </div>
                        <div className="text-muted">Fostest is Barry</div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div
                  id="kt_quick_panel_settings"
                  role="tabpanel"
                  className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps ${selectedTab === "Settings" ? "active show" : ""}`}
              >
                <form className="form">
                  <div>
                    <h5 className="font-weight-bold mb-3">Customer Care</h5>
                    <div className="form-group mb-0 row">
                      <label className="col-8 col-form-label">
                        Enable Notifications:
                      </label>
                      <div className="col-4 text-right">
                      <span className="switch switch-success switch-sm">
                        <label>
                          <input
                              type="checkbox"
                              onChange={() => {
                              }}
                              checked
                              name=""
                          />
                          <span></span>
                        </label>
                      </span>
                      </div>
                    </div>
                    <div className="form-group mb-0 row">
                      <label className="col-8 col-form-label">
                        Enable Case Tracking:
                      </label>
                      <div className="col-4 text-right">
                      <span className="switch switch-success switch-sm">
                        <label>
                          <input
                              type="checkbox"
                              name="quick_panel_notifications_2"
                              onChange={() => {
                              }}
                          />
                          <span></span>
                        </label>
                      </span>
                      </div>
                    </div>
                    <div className="form-group mb-0 row">
                      <label className="col-8 col-form-label">
                        Support Portal:
                      </label>
                      <div className="col-4 text-right">
                      <span className="switch switch-success switch-sm">
                        <label>
                          <input type="checkbox" name="" onChange={() => {
                          }}/>
                          <span></span>
                        </label>
                      </span>
                      </div>
                    </div>
                  </div>

                  <div className="separator separator-dashed my-6"></div>

                  <div className="pt-2">
                    <h5 className="font-weight-bold mb-3">Reports</h5>
                    <div className="form-group mb-0 row">
                      <label className="col-8 col-form-label">
                        Generate Reports:
                      </label>
                      <div className="col-4 text-right">
                      <span className="switch switch-sm switch-danger">
                        <label>
                          <input
                              type="checkbox"
                              name=""
                              onChange={() => {
                              }}
                              checked
                          />
                          <span></span>
                        </label>
                      </span>
                      </div>
                    </div>
                    <div className="form-group mb-0 row">
                      <label className="col-8 col-form-label">
                        Enable Report Export:
                      </label>
                      <div className="col-4 text-right">
                      <span className="switch switch-sm switch-danger">
                        <label>
                          <input type="checkbox" name="" onChange={() => {
                          }}/>
                          <span></span>
                        </label>
                      </span>
                      </div>
                    </div>
                    <div className="form-group mb-0 row">
                      <label className="col-8 col-form-label">
                        Allow Data Collection:
                      </label>
                      <div className="col-4 text-right">
                      <span className="switch switch-sm switch-danger">
                        <label>
                          <input
                              type="checkbox"
                              name=""
                              onChange={() => {
                              }}
                              checked
                          />
                          <span></span>
                        </label>
                      </span>
                      </div>
                    </div>
                  </div>

                  <div className="separator separator-dashed my-6"></div>

                  <div className="pt-2">
                    <h5 className="font-weight-bold mb-3">Memebers</h5>
                    <div className="form-group mb-0 row">
                      <label className="col-8 col-form-label">
                        Enable Member singup:
                      </label>
                      <div className="col-4 text-right">
                      <span className="switch switch-sm switch-primary">
                        <label>
                          <input
                              type="checkbox"
                              checked
                              onChange={() => {
                              }}
                              name=""
                          />
                          <span></span>
                        </label>
                      </span>
                      </div>
                    </div>
                    <div className="form-group mb-0 row">
                      <label className="col-8 col-form-label">
                        Allow User Feedbacks:
                      </label>
                      <div className="col-4 text-right">
                      <span className="switch switch-sm switch-primary">
                        <label>
                          <input type="checkbox" name="" onChange={() => {
                          }}/>
                          <span></span>
                        </label>
                      </span>
                      </div>
                    </div>
                    <div className="form-group mb-0 row">
                      <label className="col-8 col-form-label">
                        Enable Customer Portal:
                      </label>
                      <div className="col-4 text-right">
                      <span className="switch switch-sm switch-primary">
                        <label>
                          <input
                              type="checkbox"
                              checked
                              onChange={() => {
                              }}
                              name=""
                          />
                          <span></span>
                        </label>
                      </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/*end::Content*/}
        </Tab.Container>
      </div>
  );
}
