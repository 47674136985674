import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/Custom/locations/_redux/customers/customersSlice";
import {productsSlice} from "../app/modules/Custom/contract/_redux/products/productsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
