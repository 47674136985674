import React, { useEffect, useMemo } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../layout";

export function Divisionwise({ divisionWise, className, chartColor = "danger" }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_4_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps, divisionWise);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, divisionWise]);

  return (
    <>
      <div
        className={`card card-custom bg-radial-gradient-white ${className}`}
      >
        <div className="card-header border-0 pt-5">
          <h3 className="card-title font-success-bolder text-black">
            Division Wise
          </h3>
        </div>
        <div className="card-body d-flex flex-column p-0">
          <div
            id="kt_mixed_widget_4_chart"
            data-color={chartColor}
            style={{ height: "150px", minHeight: "200px" }}
          />
        </div>
      </div>
    </>
  );
}

function getChartOption(layoutProps, divisionWise) {
  const defaultOptions = {
    series: [
      {
        name: "Open W.O",
        data: divisionWise.opened || [],
      },
      {
        name: "Closed W.O",
        data: divisionWise.closed || [],
      },
    ],
    chart: {
      type: "bar",
      height: "320px",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: ["50%"],
        endingShape: "rounded",
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: false,
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      categories: divisionWise.categories || [],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    fill: {
      type: ["solid", "solid"],
      opacity: [0.25, 1],
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: true,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function (val) {
          return val;
        },
      },
      marker: {
        show: true,
      },
    },
    colors: ["#03a9f4", "#03a9f4"],
    grid: {
      borderColor: layoutProps.colorsGrayGray200,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 20,
        right: 20,
      },
    },
  };

  return defaultOptions;
}
