export default function setupAxios(axios, store) {
  // Request interceptor
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  // Response interceptor
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        // User is unauthorized, log them out
        handleLogout();
      }
      return Promise.reject(error);
    }
  );

  const handleLogout = () => {
    // Perform logout actions, e.g., clear user session
    // You can use a state management library like Redux or React Context for managing authentication state

    // Example: Clear user session
    localStorage.clear();
    sessionStorage.clear();

    // Example: Redirect to login page
    window.location.href = '/login';
  };
}
