import axios from "axios";

export const LOGIN_URL = process.env.REACT_APP_API_URL+"user/login";
export const LOGOUT_URL = process.env.REACT_APP_API_URL+"user/logout";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

//export const ME_URL = "api/me";
export const ME_URL = process.env.REACT_APP_API_URL+"user/login";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function logout() {
  return axios.get(LOGOUT_URL);
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  //let //id = window.sessionStorage.getItem("userid");
  return axios.get(ME_URL);
}
