import MockAdapter from "axios-mock-adapter";
import mockAuth from "../../app/modules/Auth/__mocks__/mockAuth";

/* export default function mockAxios(axios) {
  const mock = new MockAdapter(axios, { delayResponse: 300 });

  mockAuth(mock);
  mockCustomers(mock);
  mockProducts(mock);
  mockProjects(mock);
  mockRemarks(mock);
  mockSpecifications(mock);

  return mock;
}
 */

export default function mockAxios(axios) {
	 const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
  const mock = new MockAdapter(axiosInstance, { delayResponse: 300 });
  
  mockAuth(mock);

  return mock;
}
