import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { DashboardMaster } from "./DashboardMaster";
import { BaseDashboardAssets } from "./BaseDashboardAssets";
import { BaseDashboardKPI } from "./BaseDashboardKPI";
import axios from "axios";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export function Dashboard() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [helpdeskcount, setHelpdeskcount] = useState([]);
  const [endpoints] = useState(["dashboard", "dashboard/ppm", "dashboard/assets", "dashboard/kpi"]);


  function handleChange(event, newValue) {
    setValue(newValue);
  }
  useEffect(() => {
    setHelpdeskcount([]);
    axios.get(process.env.REACT_APP_API_URL + endpoints[value])
      .then(response => {
        setHelpdeskcount(response.data.helpdesk);
      })
      .catch(error => {
        console.log(error)
        setHelpdeskcount([]);
      });
  }, [value, endpoints]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Helpdesk Task" />
          <Tab label="PPM" />
          <Tab label="ASSET" />
          <Tab label="KPI" />
        </Tabs>
      </AppBar>
      {value === 0 && <TabContainer> <DashboardMaster helpdesk={helpdeskcount} textName={"Helpdesk"} /></TabContainer>}
      {value === 1 && <TabContainer> <DashboardMaster helpdesk={helpdeskcount} textName={"Preventive Maintenance"} /></TabContainer>}
      {value === 2 && <TabContainer> <BaseDashboardAssets /> </TabContainer>}
      {value === 3 && <TabContainer> <BaseDashboardKPI /> </TabContainer>}
    </div>
  );
}