import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import axios from 'axios';

class Logout extends Component {
  componentDidMount() {
    axios.get(process.env.REACT_APP_API_URL + 'user/logout')
      .then(response => {
        localStorage.clear();
        sessionStorage.clear();
        this.props.logout();
        console.log('Logout Successfully');
      })
      .catch(error => {
        console.error('Error logout failed:', error);
      });
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
