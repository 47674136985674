import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { useHtmlClassService } from "../../layout";

export function MonthWise({ monthWise, className, symbolShape, baseColor }) {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray200: objectPath.get(
                uiService.config,
                "js.colors.gray.gray200"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseSuccess: objectPath.get(
                uiService.config,
                `js.colors.theme.base.${baseColor}`
            ),
            colorsThemeLightSuccess: objectPath.get(
                uiService.config,
                `js.colors.theme.light.${baseColor}`
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
        };
    }, [uiService, baseColor]);

    useEffect(() => {
        const element = document.getElementById("kt_stats_widget_10_chart");

        if (!element) {
            return;
        }

        const options = getChartOption(layoutProps, monthWise);
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [layoutProps, monthWise]);

    return (
        <>
            <div className={`card card-custom ${className}`}>
                <div className="card-body p-0">
                    <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                        <span
                            className={`symbol ${symbolShape} symbol-50 symbol-light${baseColor} mr-2`}
                        >
                            <span className="symbol-label">
                                <span className={`svg-icon svg-icon-xl svg-icon-${baseColor}`}>
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-bar1.svg")}
                                    ></SVG>
                                </span>
                            </span>
                        </span>
                        <div className="d-flex flex-column text-right">
                            <span className="text-dark-75 font-weight-bolder font-size-h3">
                                Performance
                            </span>
                            <span className="text-muted font-weight-bold mt-2">Last 12 Months</span>
                        </div>
                    </div>
                    <div
                        id="kt_stats_widget_10_chart"
                        className="card-rounded-bottom"
                        data-color={baseColor}
                        style={{ height: "150px" }}
                    />
                </div>
            </div>
        </>
    );
}

function getChartOption(layoutProps, monthWise) {
    const options = {
        series: [
            {
                name: "Total Completion",
                data: monthWise.total_completion || [],
            },
        ],
        chart: {
            type: "area",
            height: "250px",
            toolbar: {
                show: false,
            },
            sparkline: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: ["50%"],
                endingShape: "rounded",
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: true,
        },
        stroke: {
            show: false,
            width: 1,
            color: layoutProps.colorsGrayGray300,
        },
        xaxis: {
            categories: monthWise.categories || [],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: layoutProps.colorsGrayGray500,
                    fontSize: "12px",
                    fontFamily: layoutProps.fontFamily,
                },
            },
        },
        fill: {
            type: ["solid", "solid"],
            opacity: [0.25, 1],
        },
        states: {
            normal: {
                filter: {
                    type: "none",
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: "none",
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: true,
                filter: {
                    type: "none",
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: "12px",
                fontFamily: layoutProps.fontFamily,
            },
            y: {
                formatter: function (val) {
                    return val + " %";
                },
            },
            marker: {
                show: true,
            },
        },
        colors: ["#ba68c8", "#03a9f4"],
        grid: {
            borderColor: layoutProps.colorsGrayGray200,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                left: 20,
                right: 20,
            },
        },
    };
    return options;
}