import React, { useState, useEffect } from "react";
import { Card, Pagination as BootstrapPagination } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { CompletionPercentage } from "./CompletionPercentage";
import { Divisionwise } from "./Divisionwise";
import { AgingWise } from "./AgingWise";
import { MonthWise } from "./MonthWise";
import { PriorityWise } from "./PriorityWise";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export function DashboardMaster({ helpdesk, textName, className }) {

  const [key, setKey] = useState("Month");
  const [value, setValue] = useState(0);
  const [percent, setPercent] = useState(0);
  const [opened, setOpened] = useState(0);
  const [closed, setClosed] = useState(0);
  const [onTime, setOnTime] = useState(0);
  const [delayed, setDelayed] = useState(0);
  const [unassigned, setUnassigned] = useState(0);
  const [totalTask, setTotalTask] = useState(0);
  const [divisionWise, setDivisionWise] = useState([]);
  const [agingWise, setAgingWise] = useState([]);
  const [monthWise, setMonthWise] = useState([]);
  const [priorityWise, setPriorityWise] = useState([]);
  const [bdmSummary, setBdmSummary] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredTasks, setFilteredTasks] = useState([]);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  useEffect(() => {
    if (helpdesk && helpdesk.tasks && helpdesk.tasks.length > 0) {
      let closedtask = helpdesk.tasks[0].closed;
      let totaltask = helpdesk.tasks[0].total;
      setOpened(helpdesk.tasks[0].opened);
      setUnassigned(helpdesk.tasks[0].unassigned);
      setOnTime(helpdesk.tasks[0].on_time_percentage);
      setDelayed(helpdesk.tasks[0].delayed_percentage);
      setClosed(closedtask);
      setTotalTask(totaltask);
      if (totaltask !== 0) {
        let taskpercent = Math.min(Math.round(closedtask * 100 / totaltask), 100);
        setPercent(taskpercent);
      } else {
        setPercent(0);
      }
    } else {
      setPercent(0);
    }
    if (helpdesk && helpdesk.divisionwise) {
      setDivisionWise(helpdesk.divisionwise);
    } else {
      setDivisionWise([]);
    }
    if (helpdesk && helpdesk.agingWise) {
      setAgingWise(helpdesk.agingWise);
    } else {
      setAgingWise([]);
    }
    if (helpdesk && helpdesk.monthWise) {
      setMonthWise(helpdesk.monthWise);
    } else {
      setMonthWise([]);
    }
    if (helpdesk && helpdesk.priorityWise) {
      setPriorityWise(helpdesk.priorityWise);
    } else {
      setPriorityWise([]);
    }
    if (helpdesk && helpdesk.bdmSummary) {
      setBdmSummary(helpdesk.bdmSummary);
    } else {
      setBdmSummary([]);
    }
  }, [helpdesk]);

  useEffect(() => {
    if (key === "Month") {
      setFilteredTasks(bdmSummary);
    } else {
      const filtered = bdmSummary.filter(task => {
        return task.range === key;
      });
      setFilteredTasks(filtered);
    }
  }, [bdmSummary, key]);

  const pageSize = 5;
  const totalPages = Math.ceil(filteredTasks.length / pageSize);
  const indexOfLastTask = currentPage * pageSize;
  const indexOfFirstTask = indexOfLastTask - pageSize;
  const currentTasks = filteredTasks.slice(indexOfFirstTask, indexOfLastTask);
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <>
      <Card>
        <div className="row">
          <div className="col-md-4">
            <div className={`card card-custom bg-radial-gradient-danger ${className}`} >
              <div className="card-header border-0 pt-5">
                <h3 className="card-title font-weight-bolder text-white">
                  Completion %
                </h3>
              </div>
            </div>
            <CompletionPercentage
              percent={percent}
              onTime={onTime}
              delayed={delayed}
              className="gutter-b"
              symbolShape="circle"
              baseColor="danger"
            />
          </div>
          <div className="col-md-4">
            <div className={`card card-custom bg-radial-gradient-danger ${className}`} >
              <div className="card-header border-0 pt-5">
                <h3 className="card-title font-weight-bolder text-white">
                  {textName}
                </h3>
              </div>
              <div className="card-body d-flex flex-column p-0">
                <div className="card-spacer bg-white card-rounded flex-grow-1">
                  <div className="row m-0">
                    <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
                      <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                      </span>
                      <div className="text-warning font-weight-bold font-size-h7">
                        Total -  {`${totalTask}`}
                      </div>
                    </div>
                    <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">
                      <div className="text-primary font-weight-bold font-size-h7 mt-2">
                        Open - {`${opened}`}
                      </div>
                    </div>
                  </div>
                  <div className="row m-0">
                    <div className="col bg-light-danger px-6 py-8 rounded-xl mr-7">
                      <div className="text-danger font-weight-bold font-size-h7 mt-2">
                        Unassigned - {`${unassigned}`}
                      </div>
                    </div>
                    <div className="col bg-light-success px-6 py-8 rounded-xl">
                      <div className="text-success font-weight-bold font-size-h7 mt-2">
                        Closed - {`${closed}`}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column w-100 mr-2  mb-15">
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="text-muted mr-2 font-size-sm font-weight-bold">
                      Total Completion - {`${percent}`}%
                    </span>
                    <span className="text-muted font-size-sm font-weight-bold">
                      Progress
                    </span>
                  </div>
                  <div className="progress progress-xs w-100">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: `${percent}%` }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className={`card card-custom bg-radial-gradient-danger ${className}`} >
              <div className="card-header border-0 pt-5">
                <h3 className="card-title font-weight-bolder text-white">
                  W.O Aging
                </h3>
              </div>
              <div className="card-body d-flex flex-column p-0">
                <AgingWise agingWise={agingWise} />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <br />
      <Card>
        <div className="row">
          <div className="col-md-4">
            <Divisionwise divisionWise={divisionWise} />
          </div>
          <div className="col-md-8">
            <div className="col-xl-12">
              <MonthWise monthWise={monthWise}
                className="gutter-b"
                symbolShape="circle"
                baseColor="danger"
              />
            </div>
          </div>
        </div>
      </Card>
      <br />
      <Card>
        <PriorityWise priorityWise={priorityWise} />
      </Card>
      <Card>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="BDM Summary" />
          </Tabs>
        </AppBar>
        {value === 0 &&
          <TabContainer>
            <div className={`card card-custom ${className}`}>
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label font-weight-bolder text-dark">
                    BDM Summary
                  </span>
                </h3>
                <div className="card-toolbar">
                  <TabContainer defaultActiveKey={key}>
                    <Nav
                      as="ul"
                      onSelect={_key => setKey(_key)}
                      className="nav nav-pills nav-pills-sm nav-dark-75"
                    >
                      <Nav.Item className="nav-item" as="li">
                        <Nav.Link
                          eventKey="Month"
                          className={`nav-link py-2 px-4 ${key === "Month" ? "active" : ""}`}
                        >
                          Month
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-item" as="li">
                        <Nav.Link
                          eventKey="Week"
                          className={`nav-link py-2 px-4 ${key === "Week" ? "active" : ""}`}
                        >
                          Week
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-item" as="li">
                        <Nav.Link
                          eventKey="Day"
                          className={`nav-link py-2 px-4 ${key === "Today" ? "active" : ""}`}
                        >
                          Today
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </TabContainer>
                </div>
              </div>
              <div className="card-body pt-3 pb-0">
                {currentTasks.length === 0 ? (
                  <div>No records found</div>
                ) : (
                  <div className="table-responsive">
                    <table className="table table-borderless table-vertical-center">
                      <thead>
                        <tr>
                          <th className="p-0" style={{ minWidth: "100px" }}>
                            Work Order Number
                          </th>
                          <th className="p-0" style={{ minWidth: "100px" }}>
                            Work Order Date
                          </th>
                          <th className="p-0" style={{ minWidth: "85px" }}>
                            Priority
                          </th>
                          <th className="p-0" style={{ minWidth: "80px" }}>
                            Status
                          </th>
                          <th className="p-0" style={{ minWidth: "150px" }}>
                            {textName === "Helpdesk" ? "Nature of Complaint" : "Frequency"}
                          </th>
                          <th className="p-0" style={{ minWidth: "150px" }}>
                            Location / Building
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentTasks.map(summary => (
                          <tr key={summary.id}>
                            <td className="pl-0">
                              <span className="text-muted font-weight-bold text-muted d-block">
                                <a href={summary.path}>
                                  {summary.work_order_no}
                                </a>
                              </span>
                            </td>
                            <td className="pl-0">{summary.work_order_date}</td>
                            <td className="pl-0">{summary.priority}</td>
                            <td className="pl-0">
                              <span className={`label label-lg label-light-${summary.status === "Approved" ? "success" : (summary.status === "Rejected" ? "danger" : "warning")} label-inline`}>
                                {summary.status}
                              </span>
                            </td>
                            <td className="pl-0">{summary.natureofcompalint ? summary.natureofcompalint : summary.frequency}</td>
                            <td className="pl-0">{summary["location/building"]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end mt-4">
              {totalPages > pageSize &&
                <>
                  <BootstrapPagination>
                    {currentPage > 1 &&
                      <>
                        <BootstrapPagination.First onClick={() => paginate(1)} />
                        <BootstrapPagination.Prev onClick={() => paginate(currentPage - 1)} />
                      </>
                    }
                    {Array.from({ length: totalPages }, (_, index) => (
                      <BootstrapPagination.Item
                        key={index + 1}
                        active={currentPage === index + 1}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </BootstrapPagination.Item>
                    ))}
                    {currentPage < totalPages &&
                      <>
                        <BootstrapPagination.Next onClick={() => paginate(currentPage + 1)} />
                        <BootstrapPagination.Last onClick={() => paginate(totalPages)} />
                      </>
                    }
                  </BootstrapPagination>
                </>
              }
            </div>
          </TabContainer>
        }
      </Card>
    </>
  );
}